'use client'
import { UserProvider } from '@/features/user/contexts/userContext'
import { SnackbarProvider } from '@/hooks/useSnackbar'
import { Suspense, type FC, type ReactNode } from 'react'

export const Providers: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Suspense>
      <SnackbarProvider>
        <UserProvider>{children}</UserProvider>
      </SnackbarProvider>
    </Suspense>
  )
}
