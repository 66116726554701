import type { AxiosInstance, CreateAxiosDefaults } from 'axios'
import { default as Axios } from 'axios'

function getAccessToken() {
  if (typeof window !== 'undefined') {
    const token = window.localStorage.getItem('token')
    if (!token) return
    if (!JSON.parse(token).accessToken) return
    return JSON.parse(token).accessToken
  }
}

type HttpProps = {
  accept?: string
  token?: boolean | string
  axiosDefaults?: CreateAxiosDefaults
}

const defaultHttpProps: HttpProps = {
  accept: 'application/json',
  token: getAccessToken(),
  axiosDefaults: {
    baseURL: process.env.NEXT_PUBLIC_API_URL,
  },
}

export class Http {
  public static axios(props?: HttpProps): AxiosInstance {
    const { axiosDefaults, accept } = {
      ...defaultHttpProps,
      ...props,
    }

    const axios = Axios.create({ ...axiosDefaults })

    axios.interceptors.request.use((config) => {
      if (config.headers && accept) {
        config.headers.Accept = accept
      }

      const token = props?.token === false ? undefined : props?.token || getAccessToken()
      if (config.headers && token) {
        config.headers.authorization = `Bearer ${token}`
      }

      return config
    })

    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    return axios
  }
}
